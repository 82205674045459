body {
    margin: 0;
    min-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: var(--surface-ground);
    font-family: var(--font-family);
    font-weight: 400;
    color: var(--text-color);
    -webkit-font-smoothing: antialiased;
}
